import React from "react";
import IrregularGrossTable from "./IrregularGross";
import InputOutputTable from "./InputOutput";
import Grid from "@mui/material/Grid2";
import {useGetInputOutput} from "../../hooks/useGetInputOutput";
import PayrollDashboard from "./PayrollDashboad";
import AgreementTab from "./AgreementTab";


interface WorkspaceProps {
    selectedTab: string;
}

export const WorkspaceContent = ({ selectedTab }: WorkspaceProps) => {
    const  inputOutput  = useGetInputOutput();
    const inputOutEmployees = inputOutput?.inputOutput?.employees ?? [];

    return (
        <Grid sx={{'padding-left': '20px', minWidth:'700px'}} >
            <PayrollDashboard active={selectedTab === 'dashboard'} />
            <IrregularGrossTable active={selectedTab === 'irregular'} />
            <InputOutputTable employees={inputOutEmployees} active={selectedTab === 'inputOutput'} />
            <AgreementTab active={selectedTab === 'agreements'} />
        </Grid>
    );
};
