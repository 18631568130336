import { Grid, styled, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";

export const Item = styled(Paper)<{ background?: string }>(({ theme, background }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2, 1, 2),
  height: theme.spacing(4),
  backgroundColor: background || theme.palette.background.default,
}));



interface StyledGridProps {
  backgroundColor?: string;
}

export const StyledGrid = styled(Grid)<StyledGridProps>(
  ({ theme, backgroundColor }) => ({
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    backgroundColor: backgroundColor,
    borderRadius: 8,
    height: theme.spacing(10),
    width: theme.spacing(20),
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
  })
);
