import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Drawer from '@mui/material/Drawer';
import { ChatComponent } from "../components/Chat/Chat";
import { StyledIconButton } from "../components/navbar/Navbar.styles";
import * as React from "react";
import {PayrollTabs} from "../components/headers/payroll-header";

const PayrollHeader = styled(Paper)(({ theme }) => ({
  elevation: 0,
  backgroundColor: '#fff',
  width: '100%',
  height: '4vh',
  color: "#0d838c",
  borderRadius: 0,
  borderBottom: "2px solid #0d838c",
  ...theme.typography.body2,
  textAlign: 'center',
}));

export const Payroll: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  return (
    <>
      <Grid container sx={{ height: '90vh'}}>
        {/* Left Side Area (Drawer) */}
        <Grid
          item
          xs={isDrawerOpen ? 4 : 0}  // Takes up space when open, disappears when closed
          sx={{
            transition: 'width 0.3s',
            "z-index": 1,
            overflow: 'hidden',  // Prevents content from overflowing
            display: isDrawerOpen ? 'block' : 'none',  // Hide the grid item completely when the drawer is closed
          }}
        >
          <Drawer
            variant="persistent"
            anchor="left"
            open={isDrawerOpen}
            sx={{
              position: 'relative',
              '& .MuiDrawer-paper': {
                position: 'relative',
                width: '100%',
                height: '70vh',
                boxSizing: 'border-box',
                transition: 'width 0.3s',
                maxWidth: '30vw'
              },
            }}
          >
            {/* Content Inside the Drawer */}
            <PayrollHeader component="div">
              <Typography variant="h4" className="header-title">Let's Talk finance</Typography>
            </PayrollHeader>
            <ChatComponent />
          </Drawer>
        </Grid>

        {/* Main Content Area */}
        <Grid
          item
          xs={isDrawerOpen ? 8 : 12}  // Takes up 8 columns when drawer is open, 12 when closed
          sx={{
            transition: 'width 0.3s',
            minHeight: '84vh',  // Ensure it takes full height
            flexGrow: 1,  // Ensures it takes up the rest of the space when the drawer is closed
          }}
        >
          <StyledIconButton
            onClick={toggleDrawer}
            sx={{
              position: "fixed",
              top: "57px",
              left: "21px",
              zIndex: 11111,
            }}
          >
            <OpenInFullIcon />
          </StyledIconButton>

          <PayrollHeader component="div">
            <Typography variant="h4" className="header-title">Payroll Workspace</Typography>
          </PayrollHeader>
          <PayrollTabs/>
        </Grid>
      </Grid>
    </>
  );
};
