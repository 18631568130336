import React, { useState } from "react";
import {FormControl, Grid2, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface MonthYearSelectProps {
  targetMonth: string;
  previousMonth: string;
  onTargetMonthChange: (value: string) => void;
  onPreviousMonthChange: (value: string) => void;
}

const generateMonths = () => {
  const months = [];
  for (let year = 2023; year <= 2024; year++) {
    for (let month = 1; month <= 12; month++) {
      const formattedMonth = month.toString().padStart(2, "0");
      months.push(`${year}/${formattedMonth}`);
    }
  }
  return months;
};

const MonthYearSelect: React.FC<MonthYearSelectProps> = ({
  targetMonth,
  previousMonth,
  onTargetMonthChange,
  onPreviousMonthChange,
}) => {
  const [months] = useState<string[]>(generateMonths());

  const handleTargetMonthChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    onTargetMonthChange(newValue);

    // Automatically update the previous month based on selection
    const selectedIndex = months.indexOf(newValue);
    if (selectedIndex > 0) {
      onPreviousMonthChange(months[selectedIndex - 1]);
    } else {
      onPreviousMonthChange(""); // No previous month available
    }
  };

  const handlePreviousMonthChange = (event: SelectChangeEvent<string>) => {
    onPreviousMonthChange(event.target.value);
  };

  return (
    <Grid2>
      {/* Target Month Selector */}
      <FormControl variant="outlined" sx={{ minWidth: 200, marginRight: 2 }}>
        <InputLabel>Target Month</InputLabel>
        <Select
          value={targetMonth}
          onChange={handleTargetMonthChange}
          label="Target Month"
        >
          {months.map((month) => (
            <MenuItem key={month} value={month}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Previous Month Selector */}
      <FormControl variant="outlined" sx={{ minWidth: 200 }}>
        <InputLabel>Previous Month</InputLabel>
        <Select
          value={previousMonth}
          onChange={handlePreviousMonthChange}
          label="Previous Month"
        >
          {months.map((month) => (
            <MenuItem key={month} value={month}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid2>
  );
};

export default MonthYearSelect;
