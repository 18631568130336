import React, { useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TablePagination,
  TableSortLabel, CircularProgress, Grid2,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Employee } from "@/types/data";
import { useGetIrregularGross } from "../../hooks/useGetIrregularGross";
import MonthYearSelect from "./MonthYearSelect";
import {formatKey, formatNumber} from "../../utils/common";

function Row(props: { employee: Employee }) {
  const { employee } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{employee["Employee name"]}</TableCell>
        <TableCell align="left">{formatNumber(employee["Gross Payroll Current Month"])}</TableCell>
        <TableCell align="left">{formatNumber(employee["Gross Payroll Previous Month"])}</TableCell>
        <TableCell align="left">{formatNumber(employee["Diff"])}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom>
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Gross Payroll Target Month</TableCell>
                    <TableCell>Gross Payroll Previous Month</TableCell>
                    <TableCell>Diff</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employee.components_break_down.map((component) => {
                    const cellStyle = {
                      fontWeight: component.component_name === 'Total' ? 600 : 'normal',
                    };
                    return (
                      <TableRow key={component.component_name}>
                        <TableCell sx={cellStyle}>{formatKey(component.component_name)}</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(component["Gross Payroll Current Month"])}</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(component["Gross Payroll Previous Month"])}</TableCell>
                        <TableCell sx={cellStyle}>{formatNumber(component.Diff)}</TableCell>
                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function IrregularGrossTable(props: { active: boolean }) {
  const { active } = props;

  // Lifted state for the selected months
  const [targetMonth, setTargetMonth] = useState("2024/09");
  const [previousMonth, setPreviousMonth] = useState("2024/08");

  // Pass the selected months to the custom hook
  const { irregularGross, loading, error } = useGetIrregularGross({targetMonth, previousMonth});
  const employees = irregularGross?.employees ?? [];

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Sorting state
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<keyof Employee>("Diff");

  const handleRequestSort = (property: keyof Employee) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortEmployees = (array: Employee[]) => {
    return array.slice().sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      const aNum = parseFloat(aValue as string);
      const bNum = parseFloat(bValue as string);

      if (!isNaN(aNum) && !isNaN(bNum)) {
        return order === "asc" ? aNum - bNum : bNum - aNum;
      }

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedEmployees = sortEmployees(employees).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  if (!active) return null;

  return (
    <>
      <Grid2 display={"flex"}>
        <MonthYearSelect
          targetMonth={targetMonth}
          previousMonth={previousMonth}
          onTargetMonthChange={setTargetMonth}
          onPreviousMonthChange={setPreviousMonth}
        />
        <Box sx={{marginLeft: "25px", marginTop: "7px", display:"inline-block", justifyContent:"center"}}>
          {loading ? <CircularProgress /> : '' }
          {error ? error : '' }
        </Box>
      </Grid2>

      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead >
            <TableRow >
              <TableCell />
              <TableCell>
                <TableSortLabel sx={{'fontWeight':'bold', fontSize: '14px'}}
                  active={orderBy === "Employee name"}
                  direction={order}
                  onClick={() => handleRequestSort("Employee name")}
                >
                  Employee name
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel sx={{'fontWeight':'bold', fontSize: '14px'}}
                  active={orderBy === "Gross Payroll Current Month"}
                  direction={order}
                  onClick={() => handleRequestSort("Gross Payroll Current Month")}
                >
                  Gross Payroll Target Month
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel sx={{'fontWeight':'bold', fontSize: '14px'}}
                  active={orderBy === "Gross Payroll Previous Month"}
                  direction={order}
                  onClick={() => handleRequestSort("Gross Payroll Previous Month")}
                >
                  Gross Payroll Previous Month
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel sx={{'fontWeight':'bold', fontSize: '14px'}}
                  active={orderBy === "Diff"}
                  direction={order}
                  onClick={() => handleRequestSort("Diff")}
                >
                  Diff
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedEmployees.map((employee) => (
              <Row key={employee["Employee name"]} employee={employee} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={employees.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
