import { useUserContext } from "../context/user-context";
import { API_BASE_URL } from "../types/constanst";
import { Kpi } from "../types/data";
import { useCallback, useEffect, useMemo, useState } from "react";

const defaultKpi: Kpi = {
  source_id: "",
  organization_id: "",
  created_date: "",
  updated_date: "",
  overall_balance: 0,
  income: 0,
  expenses: 0,
};

export const useGetKpis = (): { kpis: Kpi } => {
  const [kpis, setKpis] = useState<Kpi>(defaultKpi);
  const { user, setUser } = useUserContext();

  const options = useMemo(
    () => ({
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${user?.credential}`,
      },
    }),
    []
  );

  const fetchKpis = useCallback(async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/summary-financial-data`,
        options
      );
      const data = await response.json();
      setKpis(data);
    } catch (error) {
      throw error;
    }
  }, [options]);

  useEffect(() => {
    if (kpis === defaultKpi) {
      fetchKpis();
    }
  }, [kpis, fetchKpis]);

  return { kpis };
};
