export function formatKey(key:string) {
  if (!key){
    return key;
  }
  return key
    .split('_') // Split the string into an array by underscores
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join the words with spaces
}

export function formatNumber(value:string|number) {
  if (value == null || value === '') return ''; // Handle empty or null values
  const number = Number(value); // Convert to number if it's a string
  if (isNaN(number)) return value; // Return original value if it's not a valid number
  return new Intl.NumberFormat('en-US').format(number); // Format the number with commas
}

export function formatLargeNumber(input:number|string, decimals = 1) {
  // Convert the input to a number
  const num = Number(input);

  // Check if the conversion is valid
  if (isNaN(num)) {
    throw new Error("Invalid input: not a number");
  }

  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(decimals) + "M"; // Millions
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(decimals) + "K"; // Thousands
  } else {
    return num.toString(); // Less than a thousand
  }
}

