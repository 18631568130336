import { useUserContext } from "../context/user-context";
import { API_BASE_URL } from "../types/constanst";
import { IrregularGross } from "../types/data";
import {useCallback, useEffect, useRef, useState} from "react";

const defaultIrregulars: IrregularGross = {
  employees: [],
};

interface UseGetIrregularGrossProps {
  targetMonth: string; // e.g., "2024/09"
  previousMonth: string; // e.g., "2024/08"
}

export const useGetIrregularGross = ({
  targetMonth,
  previousMonth,
}: UseGetIrregularGrossProps): {
  irregularGross: IrregularGross;
  loading: boolean;
  error: string | null;
} => {
  const [irregularGross, setIrregularGross] = useState<IrregularGross>(defaultIrregulars);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useUserContext();

  const fetchIrregularGross = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const params = new URLSearchParams({
        target_month: targetMonth,
        previous_month: previousMonth,
      }).toString();

      const response = await fetch(
        `${API_BASE_URL}/payroll/irregular-gross-payment?${params}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${user?.credential}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setIrregularGross(data);
    } catch (err: any) {
      setError(err.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  }, [targetMonth, previousMonth, user?.credential]);

  const previousParamsRef = useRef<{ targetMonth: string; previousMonth: string } | null>(null);


useEffect(() => {
  const shouldFetch =
    !previousParamsRef.current || // First time fetch
    previousParamsRef.current.targetMonth !== targetMonth || // Target month changed
    previousParamsRef.current.previousMonth !== previousMonth; // Previous month changed

  if (shouldFetch && targetMonth && previousMonth) {
    fetchIrregularGross();

    // Update the previous parameters after fetching
    previousParamsRef.current = { targetMonth, previousMonth };
  }
}, [targetMonth, previousMonth, fetchIrregularGross]);

  return { irregularGross, loading, error };
};
