import { useUserContext } from "../context/user-context";
import { API_BASE_URL } from "../types/constanst";
import { InputOutput } from "../types/data";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

const defaultInputOutput: InputOutput = {
  employees: [],
};

export const useGetInputOutput = (): { inputOutput: InputOutput } => {
  const [InputVsOutput, setInputVsOutput] = useState<InputOutput>(defaultInputOutput);
  const { user } = useUserContext();

  const options = useMemo(
    () => ({
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${user?.credential}`,
      },
    }),
    [user?.credential]
  );

  const fetchInputOutput = useCallback(async () => {
    try {
      const params = new URLSearchParams({ target_month: '2024/09' }).toString();
      const response = await fetch(
        `${API_BASE_URL}/payroll/input-vs-output?${params}`,
        options
      );
      const data = await response.json();
      setInputVsOutput(data);
    } catch (error) {
      console.error("Error fetching input/output:", error);
    }
  }, [options]);

  // Ref to store whether the data has already been fetched
  const hasFetchedRef = useRef(false);

  useEffect(() => {
    // Only fetch if we haven't fetched data before
    if (!hasFetchedRef.current) {
      fetchInputOutput();
      hasFetchedRef.current = true; // Mark that data has been fetched
    }
  }, [fetchInputOutput]); // Run effect only once based on the fetchInputOutput function

  return { inputOutput: InputVsOutput };
};
