import { createBrowserRouter } from "react-router-dom";
import { Kpis } from "./Kpis";
import { Payroll } from "./Payroll";
import Layout from "../layout/Layout";
export const router = createBrowserRouter([
  {
    path: "/*",
    element: (
      <Layout>
        <Payroll />
      </Layout>
    ),
  },
  {
    path: "/home",
    element: (
      <Layout>
        <Payroll />
      </Layout>
    ),
  },
  {
    path: "/kpis",
    element: (
      <Layout>
        <Kpis />
      </Layout>
    ),
  },
  {
    path: "/payroll",
    element: (
      <Layout>
        <Payroll />
      </Layout>
    ),
  },
]);
