import { Grid, styled } from "@mui/material";

export const KpiContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.extra.lightGray.main,
  height: "100%",
  flexDirection: "row",
  justifyContent:"center"
}));

export const PayrollContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.extra.lightGray.main,
  height: "100%",
  flexDirection: "row",
  justifyContent:"center"
}));

export const LeftGroup = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
}));

export const RightGroup = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "30%",
  margin: theme.spacing(5, 0, 0, 5),
}));

export const FlexRow = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "16px",
  "& > *": {
    flexShrink: 0,
    flexGrow: 0,
    boxSizing: "border-box",
  },
});

export const ChartWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: theme.spacing(43),
  height: "fit-content",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  margin: theme.spacing(5, 2, 5, 5),
  boxSizing: "border-box",
}));

export const InsightCardWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(2),
  flexDirection: "column",
  "& > *": {
    marginBottom: theme.spacing(2),
  },
}));

export const PopupWrapper = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(5, 0, 0, 2),
  height: theme.spacing(10),
  overflow: "visible",
  "& > *": {
    paddingBottom: theme.spacing(2),
  },
}));

export const BarChartWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: theme.spacing(40),
  height: "fit-content",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  margin: theme.spacing(5, 2, 5, 5),
  boxSizing: "border-box",
}));