import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import {Organization} from "@/types/data";

interface OrganizationSelectProps {
  organizations: Organization[];
}

const OrganizationSelect: React.FC<OrganizationSelectProps> = ({ organizations }) => {
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  useEffect(() => {
    if (organizations.length > 0) {
      setSelectedOrg(organizations[0]);
    }
  }, [organizations]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedOrgId = event.target.value as string;
    const org = organizations.find((o) => o.org_id === selectedOrgId) || null;
    setSelectedOrg(org);
    //console.log('Selected Organization:', org);
  };

  return (
    <FormControl fullWidth sx={{marginLeft:5}}>
      <InputLabel id="organization-select-label">Company</InputLabel>
      <Select sx={{ borderRadius:"10px"}}
        labelId="organization-select-label"
        value={selectedOrg?.org_id || ''}
        onChange={handleChange}
        displayEmpty
      >
        <MenuItem value="" disabled>
          Select a Company
        </MenuItem>
        {organizations.map((org) => (
          <MenuItem key={org.org_id} value={org.org_id}>
            {org.org_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrganizationSelect;
