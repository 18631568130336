import { useState } from 'react';
import {Container, TabContainer} from './adapt-header.styles';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import { Tab } from '../Payroll/Tab';
import {WorkspaceContent} from "../Payroll/Workspace";

export const PayrollTabs: React.FC = () => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState<number>('dashboard');

    const handleClick = (tab: string) => {
        setSelectedTab(tab);
    };

    return (
        <>
            <Container>
                <Tab
                    onClick={() => handleClick('dashboard')}
                    title={t('payroll.dashboard')}
                    color={theme.palette.extra.adaptBlue.main}
                    selected={selectedTab === 'dashboard'}
                />
                <Tab
                    onClick={() => handleClick('inputOutput')}
                    title={t('payroll.inputOutput')}
                    color={theme.palette.extra.adaptBlue.main}
                    selected={selectedTab === 'inputOutput'}
                />
                <Tab
                    onClick={() => handleClick('irregular')}
                    title={t('payroll.irregular')}
                    color={theme.palette.extra.adaptBlue.main}
                    selected={selectedTab === 'irregular'}
                />
                <Tab
                    onClick={() => handleClick('agreements')}
                    title={t('payroll.agreements')}
                    color={theme.palette.extra.adaptBlue.main}
                    selected={selectedTab === 'agreements'}
                />
            </Container>
            <TabContainer>
                <WorkspaceContent selectedTab={selectedTab} />
            </TabContainer>
        </>
    );
};
