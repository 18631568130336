import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import {Select, MenuItem, Box, Typography, FormControl, InputLabel, CircularProgress} from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import {useGetPayrollDashboad} from "../../hooks/useGetPayrollDashboard";
import {formatKey, formatLargeNumber} from "../../utils/common";

const PayrollDashboard: React.FC = (props: { active: boolean }) => {
  const { active } = props;
  // State for the selected month and year
  const [selectedMonth, setSelectedMonth] = useState<number>(9);
  const [selectedYear, setSelectedYear] = useState<number>(2024);

  let payrollDashboadData = useGetPayrollDashboad(selectedMonth, selectedYear);
  let overview = payrollDashboadData.payrollDashboard?.payroll_overview ?? {};
  let payrollCost = payrollDashboadData?.payrollDashboard?.payroll_cost ?? [];
  let headCount = payrollDashboadData?.payrollDashboard?.head_count ?? [];

  if (!active) {
    return null;
  }
  return (
    <Grid container sx={{display:"block"}} >
      {/* First Row: Select Components */}
      <Grid sx={{border: "1px dashed", padding: "9px", "border-radius": "5px"}}>
        <Grid  >
        <FormControl >
          <InputLabel>Month</InputLabel>
          <Select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(Number(e.target.value))}
            variant="outlined"
            sx={{ minWidth: 120, margin: "0px 10px 8px 0px" }}
          >
          {[...Array(12)].map((_, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
        </FormControl>

        <FormControl >
          <InputLabel>Year</InputLabel>
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            variant="outlined"
            sx={{ minWidth: 120 }}
          >
          {[2022, 2023, 2024, 2025].map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
        <Box sx={{marginLeft: "25px", marginTop: "7px", display:"inline-block", justifyContent:"center"}}>
          {payrollDashboadData.loading ? <CircularProgress /> : '' }
          {payrollDashboadData.error ? payrollDashboadData.error : '' }
        </Box>

      </Grid>
        {/* Second Row: Boxes */}
        <Grid container spacing={4} marginBottom={2}>
        {Object.entries(overview ?? {}).map(([key, value], index) => (
          <Grid xs={12} sm={2.4} key={key}>
            <Box
              sx={{
                border: "1px solid #ccc",
                borderRadius: 2,
                padding: 1,
                backgroundColor:"#D2E2E3",
                textAlign: "center",
                minWidth: "100px",
              }}
            >
              <Typography variant="h4" fontWeight={300}>{formatKey(key)}</Typography>
              <Typography variant="h3" fontWeight={400} >{formatLargeNumber(value)}</Typography>
            </Box>
          </Grid>
        ))}
        </Grid>
      </Grid>


      {/* Third Row: Payroll Cost Chart */}
      <Grid marginBottom={2} marginLeft={3} >
        <Typography variant="h4" gutterBottom>
          Payroll Cost
        </Typography>
        <BarChart width={800} height={200} data={payrollCost}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="R&D" stackId="a" fill="#82ca9d" />
          <Bar dataKey="SALES" stackId="a" fill="#ffc658" />
          <Bar dataKey="CS" stackId="a" fill="#8884d8" />
          <Bar dataKey="G&A" stackId="a" fill="#855B3B" />
        </BarChart>
      </Grid>

      {/* Fourth Row: Head Count Chart */}
      <Grid marginLeft={3}>
        <Typography variant="h4" gutterBottom>
          Head Count
        </Typography>
        <BarChart width={800} height={200} data={headCount}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="R&D" stackId="a" fill="#82ca9d" />
          <Bar dataKey="SALES" stackId="a" fill="#ffc658" />
          <Bar dataKey="CS" stackId="a" fill="#8884d8" />
          <Bar dataKey="G&A" stackId="a" fill="#855B3B" />
        </BarChart>
      </Grid>
    </Grid>
  );
};

export default PayrollDashboard;
