import { Item, StyledGrid } from "./Tab.styles";
import { Typography } from "@mui/material";

interface TabsProps {
    title: string;
    selected: boolean;
    onClick: () => void;
}

export const Tab = ({ title, selected, onClick }: TabsProps) => {
    return (
        <StyledGrid onClick={onClick}>
            <Item elevation={6} background={selected ? "#0d838c" : '#ffffff'}>
                <Typography fontWeight={600} color={selected ? '#ffffff' : "#0d838c"} variant="h5" >
                    {title}
                </Typography>
            </Item>
        </StyledGrid>
    );
};
