import { CredentialResponse } from "@react-oauth/google";
import {API_BASE_URL, BASE_URL, UPLOAD_URL} from "../../types/constanst";

interface UploadResponse {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    "x-amz-security-token": string;
    policy: string;
    signature: string;
  };
}

const uploadFileToS3 = async (s3Bucket: UploadResponse, file: File,user:CredentialResponse, isAgreement:boolean) => {
  const xhr = new XMLHttpRequest();
  xhr.open("POST", s3Bucket.url, true);

  const formData = new FormData();
  Object.entries(s3Bucket.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("file", file);

  xhr.onload = () => {
    if (xhr.status === 204) {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  xhr.send(formData);
  if (isAgreement){
    await extractAgreementComponents(user, s3Bucket);
  }
};

const extractAgreementComponents = async (user:CredentialResponse, s3BucketData: UploadResponse) => {
  // sleep some time so the file will be available to the backend
  await new Promise(r => setTimeout(r, 5000));
  const params = new URLSearchParams({ file_key: s3BucketData.fields.key }).toString();
  const newAgreement = await fetch(`${API_BASE_URL}/llm/employment_agreement?${params}`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${user?.credential}`,
      "Content-Type": "application/json",
    },
    body: {},
  });

  const agreement: UploadResponse = await newAgreement.json();
};

export const UploadFile = async (
  user: CredentialResponse,
  email: string,
  file: File,
  isAgreement: boolean=false
) => {
  const bucketData = await fetch(`${BASE_URL}${UPLOAD_URL}`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${user?.credential}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_email: email,
      file_name:file.name,
    }),
  });

  const s3Bucket: UploadResponse = await bucketData.json();

  await uploadFileToS3(s3Bucket, file, user, isAgreement);
};
